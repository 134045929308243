const Error = () => {
  return (
    <div />
    // <Popup
    //   show
    //   header={error.header}
    //   subHeader={error.subHeader}
    //   buttonText={
    //     error.button === ErrorButtonType.SWITCH_TO_MAINNET
    //       ? "Switch to Mainnet"
    //       : undefined
    //   }
    //   buttonAction={
    //     error.button === ErrorButtonType.SWITCH_TO_MAINNET
    //       ? () => changeNetwork(1)
    //       : undefined
    //   }
    //   close={() => dispatch(clearError())}
    // />
  );
};

export default Error;
